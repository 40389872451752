
import { FIND_CFT_QUERY } from "@/constants/gql/ctf";
import { useQuery } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import CtfCard from "./CtfCard.vue";
import {
  MENTORING_DESKTOP_IMAGE_URL,
  MENTORING_MOBILE_IMAGE_URL,
  DISCOVER_DESKTOP_IMAGE,
  DISCOVER_MOBILE_IMAGE
} from "@/constants/imageUrls";

export default defineComponent({
  name: "Home",
  components: {
    CtfCard
  },
  setup() {
    const windowWidth = ref(window.innerWidth);

    const resizeScreen = () => {
      windowWidth.value = window.innerWidth;
    };
    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
    });

    const { result: ctfCards } = useQuery(FIND_CFT_QUERY);

    return {
      ctfCards,
      windowWidth,
      DISCOVER_DESKTOP_IMAGE,
      DISCOVER_MOBILE_IMAGE,
      MENTORING_DESKTOP_IMAGE_URL,
      MENTORING_MOBILE_IMAGE_URL
    };
  }
});
